import { getAsyncRequest } from "gx-npm-lib";
import { updateStateSubject } from "gx-npm-messenger-util";

// TODO: rm once all apps have replaced gx-npm-lib FF imports with gx-npm-feature-flags
export const getFeatureFlags = async () => {
  const name = "FEATURE_FLAG_KEYS";
  const url = "/api/env";
  const response = await getAsyncRequest(url, { withCredentials: false });
  if (Object.keys(response?.data?.flags || {}).length > 0) {
    updateStateSubject(name, response.data.flags);
  } else {
    console.error("Feature Flags are not loaded!");
    updateStateSubject(name, { failedToFetchFlags: true });
  }
};
